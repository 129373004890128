<template>
  <section>
    <div id="pdf-content" v-if="dataItem.length > 0">
      <section id="surat">
        <!-- <div class="row mb-2">
          <div class="col-11 offset-1">
            <div class="row" id="kop">
              <div class="col-2 bb">
                <img src="@/assets/logo/logo_surat.png" alt="" />
              </div>
              <div class="col-10 bb">
                <h3 id="judul">
                  PEMERINTAH KABUPATEN BANDUNG <br />
                  DINAS PEKERJAAN UMUM DAN TATA RUANG <br />
                  UPTD PENERANGAN JALAN UMUM <br />
                </h3>
                <p id="alamat">
                  Jl. RAYA SOREANG-BANJARAN KM. 3 SOREANG TELP. 5892773-5892580 –
                  SOREANG
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row" id="halaman">
          <div class="col">
            <p
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                overflow: hidden;
                text-decoration: underline;
              "
            >
              REKAPITULASI PENGADUAN PEMELIHARAAN PJU
            </p>

            <div id="content">
              <center v-if="isAssignmentLoading">
                <br />
                <br />
                <vue-loading></vue-loading>
              </center>
              <br />
              <template v-if="!isAssignmentLoading">
                <table
                  class="table table-sm table-borderless"
                  style="margin-top: -10px"
                >
                  <thead>
                     <!-- Existing header row -->
                    <tr class="text-center">
                      <th class="border-set" rowspan="2">NO</th>
                      <th class="border-set" rowspan="2">TANGGAL</th>
                      <th class="border-set" rowspan="2">SUMBER PENGADUAN</th>
                      <th class="border-set" colspan="2">Identitas Pelapor</th>
                      <th class="border-set" colspan="3">Lokasi Laporan</th>
                      <th class="border-set" rowspan="2">Uraian Laporan</th>
                    </tr>

                    <!-- New header row for additional headers -->
                    <tr class="text-center">
                      <th class="border-set single-line">Nama Pelapor</th>
                      <th class="border-set single-line">No.Telp./WA</th>
                      <th class="border-set single-line">NAMA KP. / RUAS JALAN</th>
                      <th class="border-set single-line">DESA</th>
                      <th class="border-set single-line">Kecamatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="(item, key) in dataItem" :key="key" >
                      <td class="text-center border-set">{{ key + 1 }}.</td>
                      <td class="border-set" style="width: 50%">
                        {{ item.tanggal ? formatCreatedAt(item.tanggal) : formatCreatedAt(item.created_at) }}
                      </td>
                      <td class="border-set">
                        {{ getSourceText(item.report_source) }}
                      </td>
                      <td class="border-set">{{ item.reporter }}</td>
                      <td class="border-set">{{ item.phone_reporter }}</td>
                      <td class="border-set">{{ item.address }}</td>
                      <td class="border-set">{{ item.desa ? item.desa : '-' }}</td>
                      <td class="border-set">{{ item.region.name }}</td>
                      <td class="border-set">{{ item.priority }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <p>Data Tidak Ditemukan</p>
    </div>
    <!-- Modal -->
    <CModal 
      size="sm"
      :title="'Pilih Format Print'"
      centered
      :show.sync="showModal"
      :closeOnBackdrop="false"
      >
        <template>
          <div class="modal-body">
            <p>Silahkan pilih format print</p>
          </div>
        </template>
        <template #footer>
          <div class="modal-footer">
            <CButton @click="exportAsPDF" class="btn btn-primary">PDF</CButton>
            <CButton @click="exportAsExcel" class="btn btn-primary">Excel</CButton>
            <CButton @click="closeModal" class="btn btn-secondary mr-3">Close</CButton>
          </div>
        </template>
      </CModal>
  </section>
</template>

<script>
import moment from 'moment';
import ExcelJS from 'exceljs';

export default {
  data() {
    return {
      id : null,
      dataItem: [],
      dataTeam: null,
      isAssignmentLoading: true,
      isLoading: true,
      teams : null,
      jmlTeam : null,
      params: {
        tahun: "",
        bulan: "",
      },
      params2: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      showModal: false,
    };
  },
  methods: {
    getSourceText(reportSource) {
    if (typeof reportSource === 'string') {
      switch (reportSource) {
        case "1":
          return "MEDSOS";
        case "2":
          return "Call Center";
        case "3":
          return "Pengaduan Masyarakat";
        case "4":
          return "Surat";
        case "5":
          return "Pengaduan Langsung";
        default:
          return "-";
      }
    } else if (typeof reportSource === 'number') {
      switch (reportSource) {
        case 1:
          return "MEDSOS";
        case 2:
          return "Call Center";
        case 3:
          return "Pengaduan Masyarakat";
        case 4:
          return "Surat";
        case 5:
          return "Pengaduan Langsung";
        default:
          return "-";
      }
    } else {
      return "-";
    }
  },
    formatCreatedAt(date) {
      return moment(date).locale('id').format('DD MMMM YYYY');
    },
    getData() {
      var loading = this.$loading.show();
      this.$store.dispatch("complaint/getComplaint", this.params).then((resp) => {
        loading.hide();
        this.dataItem = resp.data;
        console.log(resp);
        // setTimeout(() => {
          this.isAssignmentLoading = false;
          this.isLoading = false;
          this.showModal = true;
        // }, 2000);
        // setTimeout(function () {
        //   window.print();
        // }, 5000);
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
    getDataTeam() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("team/getTeam", this.params2)
        .then((resp) => {
          this.teams = resp.data.data
          this.jmlTeam = this.teams.length
          // console.log(this.jmlTeam)
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getMonthName(month) {
      const months = [
        "Januari", "Februari", "Maret", "April",
        "Mei", "Juni", "Juli", "Agustus",
        "September", "Oktober", "November", "Desember"
      ];

      return months[parseInt(month) - 1];
    },
    closeModal() {
      this.showModal = false;
      window.close();
    },
    exportAsPDF() {
      this.showModal = false;
      setTimeout(function () {
          window.print();
      }, 5000);
    },
    async exportAsExcel() {
  if (!this.dataItem || this.dataItem.length === 0) {
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Rekapitulasi');

  // Define header rows to match the table structure with colspans
  const titleRow = [
    'NO',
    'TANGGAL',
    'SUMBER PENGADUAN',
    'Identitas Pelapor',
    '',
    'Lokasi Laporan',
    '',
    '',
    'Uraian Laporan',
  ];

  const headerRows = [
    titleRow, // First title row with colspans
    [
      '',
      '',
      '',
      'Nama Pelapor',
      'No.Telp./WA',
      'NAMA KP. / RUAS JALAN',
      'DESA',
      'Kecamatan',
      '',
    ],
  ];

 
  // Add header rows to the worksheet

    headerRows.forEach((row) => {
      const rowNumber = worksheet.addRow(row);
      rowNumber.eachCell({ includeEmpty: true }, (cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        // Add border to header cells
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } }, // Black color
          bottom: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
      });
    });

    const identitasPelaporCell = worksheet.getCell('D1');
      identitasPelaporCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFD965' }, // Orange color (#FFD965)
    };

    const namaPelaporCell = worksheet.getCell('D2');
      namaPelaporCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFD965' }, // Orange color (#FFD965)
      };

    const noTelpWACell = worksheet.getCell('E2');
    noTelpWACell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFD965' }, // Orange color (#FFD965)
    };

    // Set background color for "Lokasi Laporan" in titleRow to green (#C5E0B3)
    const lokasiLaporanCell = worksheet.getCell('F1');
    lokasiLaporanCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC5E0B3' }, // Green color (#C5E0B3)
    };

    // Set background color for "NAMA KP. / RUAS JALAN," "DESA," "Kecamatan," and "Uraian Laporan" in headerRow to green
    const namaKpRuasJalanCell = worksheet.getCell('F2');
    namaKpRuasJalanCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC5E0B3' }, // Green color (#C5E0B3)
    };

    const desaCell = worksheet.getCell('G2');
    desaCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC5E0B3' }, // Green color (#C5E0B3)
    };

    const kecamatanCell = worksheet.getCell('H2');
    kecamatanCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC5E0B3' }, // Green color (#C5E0B3)
    };

    const uraianLaporanCell = worksheet.getCell('I1');
    uraianLaporanCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC5E0B3' }, // Green color (#C5E0B3)
    };

  // Merge cells for the title columns with the corresponding data columns
  worksheet.mergeCells('A1:A2');
  worksheet.mergeCells('B1:B2');
  worksheet.mergeCells('C1:C2');
  worksheet.mergeCells('D1:E1'); // Merge Identitas Pelapor (columns D and E)
  worksheet.mergeCells('F1:H1'); // Merge Lokasi Laporan (columns F, G, and H)
  worksheet.mergeCells('I1:I2');

  // Set column widths
  worksheet.columns = [
    { key: 'no', width: 15 }, // "NO" column width set to 15
    { key: 'tanggal', width: 20 }, // Width for other columns set to 20
    { key: 'sumber_pengaduan', width: 20 },
    { key: 'nama_pelapor', width: 30 }, // Using "nama_pelapor" for "No Pelanggan"
    { key: 'no_telp_wa', width: 20 }, // Using "no_telp_wa" for "No Telp./WA"
    { key: 'nama_kp_ruas_jalan', width: 40 }, // Using "nama_kp_ruas_jalan" for "NAMA KP. / RUAS JALAN"
    { key: 'desa', width: 20 }, // Using "desa" for "DESA"
    { key: 'kecamatan', width: 20 }, // Using "kecamatan" for "Kecamatan"
    { key: 'uraian_laporan', width: 20 }, // Using "uraian_laporan" for "Uraian Laporan"
  ];

  // Populate the worksheet with data from this.dataItem
  this.dataItem.forEach((item, index) => {
    const row = worksheet.addRow({
      no: index + 1,
      tanggal: item.tanggal ? this.formatCreatedAt(item.tanggal) : this.formatCreatedAt(item.created_at),
      sumber_pengaduan: this.getSourceText(item.report_source),
      nama_pelapor: item.reporter,
      no_telp_wa: item.phone_reporter,
      nama_kp_ruas_jalan: item.address,
      desa: item.desa ? item.desa : "-",
      kecamatan: item.region.name,
      uraian_laporan: item.priority,
    });

    // Center align data text
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        // Add border to data cells
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
      });

    // Set background color for "Identitas Pelapor," "Nama Pelapor," and "No.Telp./WA" columns to orange (#FFD965)
    for (let col of ['D', 'E']) {
      row.getCell(col).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFD965' }, // Orange color (#FFD965)
      };
    }

    // Set background color for "Lokasi Laporan," "NAMA KP. / RUAS JALAN," "DESA," "Kecamatan," and "Uraian Laporan" columns to green (#C5E0B3)
    for (let col of ['F', 'G', 'H', 'I']) {
      row.getCell(col).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC5E0B3' }, // Green color (#C5E0B3)
      };
    }
  });

  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob object and download the file
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'rekapitulasi.xlsx';
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
}
  },
  computed: {
    computedData() {
      return this.dataItem.map((item) => {
        return {
          ...item,
        }
      });
    }
  },
  created() {
    this.params.tahun = this.$route.query.tahun;
    this.params.bulan = this.$route.query.bulan;
    this.params.fromDate = this.$route.query.fromDate || "";
    this.params.toDate = this.$route.query.toDate || "";
  },
  mounted() {
    this.getData()
    this.getDataTeam()
  },
};
</script>
  
  
<style scoped>
  body {
    font-size: 9px;
  }
  
  #judul {
    color: black;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  }
  #alamat {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
  }
  #kop {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 38px;
    padding-right: 19px;
    /* padding-bottom: 28px; */
    border-bottom: 11px double black;
  }
  #halaman {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 113px;
    padding-right: 76px;
    padding-bottom: 28px;
    font-family: "Times New Roman", Times, serif;
  }
  img {
    color: black;
    height: 110px;
  }
  #content {
    color: black;
    text-align: justify;
    font-size: 20px;
  }
  .border-set {
    /* font-size: 20px; */
    border: 2px solid #000 !important;
  }
  p {
    color: black;
    margin: 5px;
  }
</style>

<style>
@media print {
  @page {
    size: landscape;
  }
}
#pdf-content {
  width: calc(29.7cm - 2cm * 2);
  /* margin: 28pt 32pt 28pt 32pt;
        word-spacing: 1pt;
        word-break: page-break; */
  color: #000 !important;
}
#content-table {
  font-size: 8pt;
  /* border-top: 1pt solid black;
  border-left: 1pt solid black; */
  border-collapse: collapse;
}
#content-table th,
#content-table td {
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  border: 1px solid black;
  padding-left: 3pt;
  padding-right: 3pt;
}
#footer {
  font-size: 8pt;
}
table {
  white-space: normal !important;
}
.single-line {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text if needed */
}
</style>